import { InteractionStatus } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useRouter } from 'next/dist/client/router'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, ReactNode, useCallback, useEffect } from 'react'

import SectionLoading from 'components/elements/SectionLoading'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import Unauthenticated from 'components/modules/Auth/Unauthenticated'
import { RoutePaths } from 'config/routes'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

interface GuardAuthorizedProps {
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'GuardAuthorized'

const GuardAuthorized: FunctionComponent<GuardAuthorizedProps> = ({ children }) => {
  const { lang } = useTranslation()
  const { status } = useAuthState()
  const { isReady, push, route } = useRouter()
  const { instance, inProgress } = useMsal()
  const {
    data: { all },
    isLoading: businessUnitIsLoading,
  } = useBusinessUnit()

  const logOut = useCallback<VoidFunction>(async () => {
    const redirectURI = new URL(process.env.NEXT_PUBLIC_SITE_URL + `/${lang}${RoutePaths.login}`)
    debugger;
    await instance.logoutRedirect({ postLogoutRedirectUri: redirectURI.href })
  }, [instance, lang])

  useEffect(() => {
    const canAccessStore = all?.some((bu) => bu.status === 'Active')
    const noInteractionInProgress = inProgress === InteractionStatus.None
    if (!businessUnitIsLoading && !canAccessStore && noInteractionInProgress) {
      logOut()
    } else if (isReady && status === AuthStatus.Unauthenticated && route === RoutePaths.home) {
      push(RoutePaths.login)
    }
  }, [all, inProgress, businessUnitIsLoading, isReady, logOut, push, route, status])

  if (status === AuthStatus.Loading) {
    return <SectionLoading />
  }

  if (status === AuthStatus.Unauthenticated) {
    if (route === RoutePaths.home) {
      return <SectionLoading />
    }
    return <Unauthenticated />
  }

  return <>{children}</>
}

GuardAuthorized.displayName = TAG

export default GuardAuthorized
