/**
 * Admin routes
 */
export const ADMIN_ROUTES = {
  admin: '/admin/',
  adminMetalSurcharge: '/admin/metal-surcharge/',
  adminMetalSurchargeUploadShared: '/admin/metal-surcharge/shared/',
  adminMetalSurchargeUploadCountry: '/admin/metal-surcharge/country/',
  adminMetalSurchargeUploadCompany: '/admin/metal-surcharge/company/',
} as const satisfies Record<string, string>

/**
 * Routes that are accessible only when user is logged in
 */
export const LOGGED_IN_ROUTES = {
  home: '/',
  cart: '/cart/',
  checkout: '/checkout/',
  orderConfirmation: '/order-confirmation/',
  productDetails: '/product/[slug]/',
  products: '/products/', // aka 'all products'
  productsCategory: '/products/[[...slug]]/',
  /// My Account
  my: '/my/',
  users: '/my/users/',
  usersCreate: '/my/users/create/',
  user: '/my/users/[slug]/',
  userEdit: '/my/users/[slug]/edit/',
  order: '/my/orders/[slug]/',
  orders: '/my/orders/',
  profile: '/my/profile/',
  recentlyViewed: '/my/recently-viewed/',
  shoppingLists: '/my/shopping-lists/',
  shoppingList: '/my/shopping-lists/[slug]/',
  blanketAgreements: '/my/blanket-agreements/',
  pendingCompanyRequests: '/my/pending-company-requests/',
  metalSurcharge: '/my/metal-surcharge/',
} as const satisfies Record<string, string>

/**
 * Routes that are accessible only when user is NOT logged in
 */
export const NOT_LOGGED_IN_ROUTES = {
  login: '/login/',
  verifyEmail: '/auth/verify-email/',
  about: '/about-this-site/',
} as const satisfies Record<string, string>

/**
 * Public routes that are accessible to everyone.
 */
const PUBLIC_ROUTES = {
  design: '/design/',
  maintenance: '/maintenance/',
  about: '/about-this-site/',
  termsOfOrder: '/about-this-site/terms-of-order/',
  termsOfUse: '/about-this-site/terms-of-use/',
} as const satisfies Record<string, string>

/**
 * API routes
 */
export const API_ROUTES = {
  ordersApi: '/api/azure/orders/',
  orderDocumentsApi: '/api/azure/orders/documents/',
  deliveryApi: '/api/azure/delivery/',
  metalSurchargeApi: '/api/azure/metalsurcharge/',
  metalSurchargeAdminApi: '/api/azure/metalsurchargeadmin/',
  cartsApi: '/api/commerce-tools/carts/',
  orderApi: '/api/commerce-tools/orders/',
  customersApi: '/api/commerce-tools/customers/',
  productProjectionsApi: '/api/commerce-tools/productProjections/',
  productProjectionsSearchApi: '/api/commerce-tools/productProjections/search/',
  customerGroupsApi: '/api/commerce-tools/customer-groups/',
  shoppingListApi: '/api/commerce-tools/shopping-list/',
} as const satisfies Record<string, string>

export const RoutePaths = {
  ...PUBLIC_ROUTES,
  ...NOT_LOGGED_IN_ROUTES,
  ...LOGGED_IN_ROUTES,
  ...API_ROUTES,
  ...ADMIN_ROUTES,
} as const satisfies Record<string, string>

export type RoutePathsKeys = keyof typeof RoutePaths
export type RoutePathsValues = (typeof RoutePaths)[RoutePathsKeys]
