import { Icon } from '@chakra-ui/react'

import FacebookIcon from 'assets/facebook.svg?component'
import LinkedinIcon from 'assets/linkedIn.svg?component'
import TwitterIcon from 'assets/twitter.svg?component'
import YoutubeIcon from 'assets/youtube.svg?component'
import type { MenuLink } from 'components/elements/MenuList'
import { RoutePaths } from 'config/routes'

export const FOOTER_MENU_ABOUT = [
  { href: RoutePaths.about, translateKey: 'components.footer.about.aboutKanthal' },
  {
    href: 'https://www.kanthal.com/en/knowledge-hub/news/',
    translateKey: 'components.footer.about.pressNews', target: '_blank'
  },
] as const satisfies ReadonlyArray<MenuLink>

export const FOOTER_MENU_SOCIALS = [
  {
    href: 'https://linkedin.com/company/kanthal/',
    translateKey: 'components.footer.socials.linkedin',
    target: '_blank',
    children: <Icon aria-hidden="true" as={LinkedinIcon} boxSize="auto" />,
  },
  {
    href: 'https://facebook.com/Kanthal/',
    translateKey: 'components.footer.socials.facebook',
    target: '_blank',
    children: <Icon aria-hidden="true" as={FacebookIcon} boxSize="auto" />,
  },
  {
    href: 'https://youtube.com/user/kanthalproducts',
    translateKey: 'components.footer.socials.youtube',
    target: '_blank',
    children: <Icon aria-hidden="true" as={YoutubeIcon} boxSize="auto" />,
  },
  {
    href: 'https://twitter.com/Kanthal',
    translateKey: 'components.footer.socials.twitter',
    target: '_blank',
    children: <Icon aria-hidden="true" as={TwitterIcon} boxSize="auto" />,
  }

] as const satisfies ReadonlyArray<MenuLink>

export const FOOTER_MENU_BOTTOM_ROW = [
  {
    href: 'https://www.kanthal.com/en/about-us/',
    translateKey: 'components.footer.bottomRow.privacy',
    target: '_blank',
  },
  {
    href: 'https://www.kanthal.com/en/trademarks/',
    translateKey: 'components.footer.bottomRow.trademarks',
    target: '_blank',
  },
  {
    href: '',
    translateKey: 'components.footer.bottomRow.cookieSettings',
    as: 'button',
    id: 'ot-sdk-btn',
    className: 'ot-sdk-show-settings custom-ot-sdk-btn',
  },
] as const satisfies ReadonlyArray<MenuLink>
